import React, { useState } from "react";
import Image from "next/image";
import { useCookie } from "next-cookie";
import { useRouter } from "next/router";
import {
  TRACK_LOGIN_SUCCESS,
  TRACK_REGISTER_SELECTED,
} from "../../../utils/trackEvent";
import { Modal } from "react-bootstrap";
import { useStateContext } from "../../../context";
import analytic from "../../../utils/analytic";
import Link from "next/link";

const LoginDialog = (props) => {
  const cookie = useCookie(props.cookie);
  const [emailInput, setEmail] = useState("");
  const [passwordInput, setPassword] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const router = useRouter();

  const [state, setState] = useStateContext();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onLoginFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    fetch(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailInput,
        password: passwordInput,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == true) {
          cookie.set("user_id", data.data.userid, { path: "/" });
          cookie.set("token", data.data.access_token, { path: "/" });

          analytic.setUserId(data.data.userid);

          TRACK_LOGIN_SUCCESS();

          setState({
            ...state,
            token: data.data.access_token,
            loginModal: false,
          });

          window.location.reload();

          // if (typeof router.query.continue !== "undefined") {
          //   router.push(router.query.continue);
          // } else {
          //   router.push("/");
          // }
        } else if (data.status == false) {
          setIsSubmiting(false);
          alert(data.messages.join(","));
        } else {
          setIsSubmiting(false);
          alert("something wrong, we are working on it");
        }
      })
      .catch((error) => {
        alert("something wrong, we are working on it");
        console.log(error);
        setIsSubmiting(false);
      });
  };

  const onRegisterLinkSubmit = (e) => {
    e.preventDefault();
    window.open(
      `${
        process.env.NEXT_PUBLIC_BASE_ADMIN_URL
      }/daftar-member?continue=${encodeURIComponent(
        `?continue=${router.query.continue}`
      )}`
    );
    TRACK_REGISTER_SELECTED();
  };

  return (
    <Modal
      contentClassName="card"
      size="lg"
      show={state.loginModal}
      centered
      onHide={() => setState({ ...state, loginModal: false })}
    >
      <div className="row">
        <div className="col-12 col-md-6 ">
          <div className="card-body p-4">
            <h1 className="text-center mb-2">Login</h1>
            <form onSubmit={onLoginFormSubmit}>
              <div className="mb-3">
                <label htmlFor="email">Email*</label>
                <input
                  className={"form-control"}
                  type="email"
                  id="email"
                  required
                  value={emailInput}
                  onChange={onEmailChange}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password">Password*</label>
                <input
                  className={"form-control"}
                  type="password"
                  id="password"
                  required
                  value={passwordInput}
                  onChange={onPasswordChange}
                />
              </div>
              <div className="d-flex justify-content-end">
                <Link href="/reset-password">
                  <a>Lupa password ?</a>
                </Link>
              </div>
              <input
                type="submit"
                className="my-3 btn btn-primary w-100"
                value="Login"
                disabled={isSubmiting}
              />
            </form>

            <div className="text-center">
              Belum punya akun ?{" "}
              <a href="#" onClick={onRegisterLinkSubmit}>
                Daftar
              </a>
            </div>
          </div>
        </div>

        <div className="d-none d-md-block col-12 col-md-6 login-right-bg-img">
          <div className="d-flex flex-column justify-content-between card-body p-4">
            <div className="d-flex flex-column gap-3 text-white">
              <div>
                <Image
                  src="/logo_rmd.png"
                  alt="logo rumahdewi"
                  width="200px"
                  height="60px"
                  quality={100}
                />
              </div>
              <p>
                Rumahdewi.com adalah situs properti terbaik yang akan memberikan
                anda kemudahan dalam mencari properti baik dijual maupun disewa
                dengan informasi seperti spesifikasi, foto bahkan peta.
              </p>
              <p>
                Anda juga dapat menemukan Agen Properti yang akan membantu Anda
                dalam bertransaksi properti.
              </p>
            </div>

            {/* <div className="d-flex gap-2">
              <Image
                src="/app-store.png"
                alt="logo app store"
                width="100px"
                height="30px"
                quality={100}
              />
              <Image
                src="/google-play.png"
                alt="logo google play"
                width="100px"
                height="30px"
                quality={100}
              />
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export function getServerSideProps(context) {
  const cookie = useCookie(context);

  if (cookie.get("token")) {
    return {
      redirect: {
        destination: "/",
      },
    };
  }

  return {
    props: {},
    // props: {
    //   cookie: context.req.headers.cookie || ''
    // }
  };
}

export default LoginDialog;
